import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import styled from 'styled-components'

import { ContentPage } from './ContentPage'

const Content = styled.div`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 8])};
  }
`

const Page = styled.div`
  ${grid()};
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(9)};
  }
`

// don't use this component, it is for migration purposes
export const ContentPageWrapped = props => (
  <Page>
    <Content>
      <ContentPage {...props} />
    </Content>
  </Page>
)
