import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import ContentHero from '@pretto/bricks/website/shared/components/ContentHero'

import { ContentPageWrapped } from '@pretto/website/src/components/ContentPage/ContentPageWrapped'

import PropTypes from 'prop-types'
import qs from 'qs'
import { useEffect, useState } from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

const PreviewPage = ({ location }) => {
  const [content, setContent] = useState('')
  const [image, setImage] = useState('')
  const [title, setTitle] = useState('')

  useEffect(() => {
    const { id, nonce, type } = qs.parse(location.search, { ignoreQueryPrefix: true })

    fetch(API_URL, {
      body: JSON.stringify({
        query: `
          query($id: ID!) {
            postType: ${type}(id: $id, idType: DATABASE_ID) {
              postTypeMeta {
                highlightedImage: highlightedimage
              }
              content
              title
            }
          }`,
        variables: { id },
      }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-Wp-Nonce': nonce,
      },
      method: 'POST',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error()
        }

        return response.json()
      })
      .then(({ data }) => {
        setContent(data.postType?.content)
        setImage(data.postType?.postTypeMeta.highlightedImage)
        setTitle(data.postType?.title)
      })
      .catch(() => {})
  }, [])

  return (
    <Layout>
      {title && (
        <Wrapper>
          <ContentHero image={image} title={title} titleAlignment="center" />
        </Wrapper>
      )}

      {content && <ContentPageWrapped data={{ content }} />}

      <SEO noindex title={title ? `${title} - Aperçu - Pretto` : 'Aperçu - Pretto'} />
    </Layout>
  )
}

PreviewPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default PreviewPage
